import Vue from 'vue'
import Vuex from 'vuex'
import { App, User } from '@/model'
import uiModule from '@/store/ui'
import { Config } from '@/utils'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: User,
		app : App,
	},
	getters: {
		user: state => state.user,
		app: state => state.app,
	},
	mutations: {
		SetUser(state, user) {
			if (user && user.Token)	{
				Vue.ls.set(Config.ACCESS_TOKEN, user.Token);
			}else {
				Vue.ls.remove(Config.ACCESS_TOKEN);
			}
			
			state.user = user;			
		}, 
		SetApp(state, app) {
			state.app = app;
		}
	},
	actions: {
	},
	modules: {
		ui: uiModule,
	}
})
